import React from 'react';
import Image from 'gatsby-image';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import FormDevis from '../components/FormDevis';
import Testimonials from '../components/Testimonials';
import Zones from '../components/Zones';
import Prestations from '../components/Prestations';

import MainCta from '../components/MainCta';

import backgroundImage from '../images/vaud-bg.jpg';
import Breadcrumb from '../components/Breadcrumb';
import BlogSection from '../components/BlogSection';
import Layout from '../components/Layout';

export const query = graphql`
  query VaudImages {
    teamImage: file(relativePath: { eq: "serrurier-team.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tranquille: file(relativePath: { eq: "tranquilite.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    deplacementGratuitImage: file(
      relativePath: { eq: "deplacement-gratuit.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vaudMap: file(relativePath: { eq: "canton_vaud.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const pages = [{ name: 'Vaud', href: '/vaud', current: true }];

export default function Vaud({ data }) {
  const { vaudMap, deplacementGratuitImage, tranquille, teamImage } = data;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Serrurier vaud, pas cher et pro, devis en 30 secondes - dépannage 24/7
        </title>
        <meta
          name="description"
          content="Leader de la serrurerie depuis 1998 +3000 clients font confiance à Super Serrurier chaque année à travers la Suisse romande "
        />
      </Helmet>
      <div className="relative bg-gray-100 overflow-hidden">
        <MainTownHeader
          className="relative bg-white pt-6 pb-16 sm:pb-24 py-16"
          id="main-jumbo"
        >
          <main className="mt-24 mx-auto max-w-7xl px-4 sm:mt-10">
            <div className="text-center">
              <div className="flex justify-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-primarycolor sm:text-white sm:text-5xl md:text-6xl ml-4">
                  Serrurier Vaud
                </h1>
              </div>

              {/* 
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Serrurier</span>
              <span className="block text-white xl:inline">Vaud</span>
            </h1>
              */}
              <h2 className="text-1xl lg:text-2xl xl:text-3xl md:block mt-3 text-gray-700 sm:text-white">
                Profitez d’un service de qualité et sans mauvaises surprises
              </h2>
              <p className="text-sm lg:text-lg text-gray-700 sm:text-white mt-4">
                Super Serrurier, le plus fiable du canton de Vaud
              </p>
              {/* 
            <p className="max-w-md mx-auto text-base text-white sm:text-lg md:text-xl md:max-w-3xl">
              Profitez d’un service de qualité et sans mauvaises surprises
            </p>
            */}
            </div>

            <div className="flex justify-center">
              <a
                href="tel:0791304768"
                className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
              >
                <span className="px-3 py-2 text-white text-lg lg:text-xl xl:text-1xl leading-5 tracking-wide bg-primarycolor rounded-full">
                  Appelez-nous
                </span>
                <span className="ml-3 text-lg lg:text-xl xl:text-1xl font-semibold leading-5">
                  079 130 47 68
                </span>
                {/* <!-- Heroicon name: chevron-right --> */}
                <svg
                  className="ml-2 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <Hr className="hidden md:block" />
            <MainCta />
          </main>
        </MainTownHeader>
        <Breadcrumb pages={pages} />
        <div className="ml-2 md:py-16 bg-white overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
              <div className="relative py-12">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Ethique et transparence des prix
                  </h3>
                  {/*
                <!-- <h2 className="inline">
                - Faites vous dépanner par les meilleurs serruriers de votre ville
                </h2> --> */}
                </div>
                <p className="mt-6 text-lg text-gray-600">
                  Depuis notre création en 1998, nous appliquons une
                  transparence totale des prix. Appelez-nous ou écrivez-nous en
                  décrivant votre besoin, et nous vous transmettrons
                  instantanément un devis gratuit et définitif.
                </p>
                <div className="mt-8 sm:flex">
                  <div className="block md:hidden rounded-md shadow">
                    <a
                      href="tel:0791304768"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                    >
                      Appelez-nous
                    </a>
                  </div>
                  <div className="hidden md:block mt-3 sm:mt-0 lg:ml-0">
                    <a
                      href="/contact"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white shadow-sm bg-secondarycolor hover:bg-secondarycolor-light"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <div className="bg-white p-8 shadow-lg rounded-lg z-10">
                  <FormDevis />
                </div>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                    <span className="text-primarycolor">
                      Un réseau de serruriers hors pair couvrent tout le canton
                      de Vaud
                    </span>
                  </h3>
                  <div className="mt-3 text-lg text-gray-600">
                    <p>
                      Nous mettons à votre disposition une équipe de d’experts
                      serruriers dans tout le Canton de Vaud. Nos serruriers
                      peuvent desservir Lausanne et toute sa périphérie, ainsi
                      que toutes les autres régions vaudoises, telles que Nyon,
                      Morges, Vevey, Montreux, Payerne, etc. Nos emplacements
                      stratégiques, nous permettent de vous assurer une
                      intervention très rapide, en moins de 20 minutes même pour
                      un dépannage d’urgence, en vous garantissant bien
                      évidemment une prestation de qualité inégalable.
                    </p>
                  </div>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <div className="w-2/3 lg:w-5/6 mx-auto">
                    <Image
                      className="relative mx-auto"
                      fluid={vaudMap.childImageSharp.fluid}
                      alt="Canton de Vaud"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Déplacement gratuit et sans engagement
                  </h3>
                  {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                </div>
                <p className="mt-3 text-lg text-gray-600">
                  Nos Super Serruriers se déplacent chez vous gratuitement, afin
                  de vous faire bénéficier d’un avis professionnel et d’un devis
                  gratuit. C’est une de nos prestations les plus appréciée par
                  nos clients N’attendez pas, appelez-nous!
                </p>
                <div className="mt-8 sm:flex">
                  <div className="block md:hidden rounded-md shadow">
                    <a
                      href="tel:0791304768"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                    >
                      Appelez-nous
                    </a>
                  </div>
                  <div className="hidden md:block mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                    <a
                      href="/contact"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <div className="mx-auto mb-12 lg:mb-0 w-5/6 lg:w-full">
                  <Image
                    fluid={deplacementGratuitImage.childImageSharp.fluid}
                    style={{ borderRadius: '7px' }}
                    className="relative mx-auto"
                    alt="Déplacement gratuit et sans engagement"
                  />
                </div>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
          </div>
        </div>
        <Testimonials />

        <div className="ml-2 mt-4 md:py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-0">
              <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="relative mt-12 lg:mt-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Tranquillité d’esprit
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Nos Super Serruriers sont dignes de confiance, et sont
                      formés pour un service de haute qualité. Super Serrurier
                      s’engage et s'investit pour vous garantir un service
                      client irréprochable, et vous fait bénéficier des
                      meilleures prestations avec une qualité et un prix
                      inégalables.
                    </p>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      style={{ borderRaduis: '7px' }}
                      className="relative mx-auto"
                      fluid={tranquille.childImageSharp.fluid}
                      alt="Tranquillité d’esprit"
                    />
                  </div>
                </div>
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-28 lg:items-center mt-12 lg:mt-32">
                  <div className="lg:col-start-2">
                    <h2 className="text-2xl font-extrabold text-primarycolor tracking-tight sm:text-3xl">
                      Notre succès: un travail de pro à très bon prix
                    </h2>
                    <div className="mt-3 text-lg text-gray-600">
                      <p>
                        Super Serruriers sert plus de 3’000 clients par an avec
                        un taux de satisfaction de plus 98%. En plus d’avoir une
                        équipe de confiance, nous devons notre succès à notre
                        politique de prix justes et pas chers.
                      </p>
                    </div>
                  </div>
                  <div className=" -mx-4 relative lg:mt-0 lg:col-start-1">
                    <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width="784"
                      height="244"
                      fill="none"
                      viewBox="0 0 784 404"
                      aria-hidden="true"
                    >
                      <defs>
                        <pattern
                          id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          ></rect>
                        </pattern>
                      </defs>
                      <rect
                        width="784"
                        height="404"
                        fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                      ></rect>
                    </svg>
                    <Image
                      className="relative mx-auto"
                      fluid={teamImage.childImageSharp.fluid}
                      src="https://res.cloudinary.com/dnzgotmwo/image/upload/v1606606743/super/A_propos_ak2tjr.png"
                      alt="Notre succès: un travail de pro à très bon prix"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Zones
          title="Nos zones d’interventions"
          text="Nous avons un réseau de serruriers disponibles 24/7 toute l’année même les jours fériés, dans tout le Canton de Vaud. Vous trouverez toujours un super serrurier à Lausanne près de chez vous où que vous soyez."
        />
        <Prestations title="Nos services" />

        <BlogSection />
      </div>
    </Layout>
  );
}

const MainTownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    background-image: none;
  }
`;

const Hr = styled.hr`
    height: 1.5px;
    width: 15%;
    background: #fff;
    margin: auto;
    margin-top: 2rem;
}
`;
